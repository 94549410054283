import { Switch, Route, Redirect } from "react-router-dom";
//import { Routes, Route, Navigate } from "react-router-dom";
import UserManagement from "../Screens/Administrator/UserManagement/UserManagement";
import NavigationPage from "../Screens/AppNavigation/Navigation";
import DigitalID from "../Screens/DigitalID/DigitalID";
import Landing from "../Screens/LandingPage/Landing";
import QRHome from "../Screens/QRCalibration/Home/Home";
import FileUpload from "../Screens/Upload/FileUpload";
import ViewDetails from "../Screens/Upload/ViewDetails";
import CaliberationList from "../Screens/QRCalibration/List/CalliberationList";
import QrInputs from "../Screens/QRCalibration/Upload/QRInputs";
import QRCalibrationLanding from "../Screens/LandingPage/QRCalibrationLanding";
import AdminLandingPage from "../Screens/Administrator/AdminLanding";
import FileUploadExternal from "../Screens/External/FileUploadExternal";
import ApproveReject from "../Screens/Administrator/ApproveReject";
import Error from "../Screens/Error/Error404";
import DetailsPage from "../Screens/LoginRegister/DetailsPage";
import GetCalibCertDetails from "../Screens/LoginRegister/ShowCalibCert";

export const MasterRoute = (
  <Switch>
    <Redirect from="/signin-oidc*" to="/" />
    <Redirect from="/signin-oidc#code=*" to="/" />
        <Route exact path="/" render={() => <Landing />} />
     {/*   <Route exact path="/home" element={() => <Landing />} />*/}
        <Route exact path="/viewDetail" render={() => <ViewDetails />} />
        {/*<Route exact path="/fileUpload" element={() => <FileUpload />} />*/}
        <Route exact path="/admin" render={() => <AdminLandingPage />} />
        <Route exact path="/userManagement" render={() => <UserManagement />} />
          <Route exact path="/fileUpload" render={() => <FileUpload />} />
          <Route exact path="/approveReject" render={() => <ApproveReject />} />
   
    <Route exact path="/digitalId" element={() => <DigitalID />} /> 
    {/*<Route*/}
    {/*  exact*/}
    {/*  path="/caliberationList"*/}
    {/*  element={() => <CaliberationList />}*/}
    {/*/>*/}
        {/*    <Route exact path="/caliberationUpload" element={() => <QrInputs />} />*/}
        <Route exact path="/GetDetails" render={() => <DetailsPage />} />
        <Route exact path="/QRScanCalibrationCertificateFTP" render={() => <GetCalibCertDetails />} />
        <Route render={() => <Error />} />
  </Switch>
);

export const ElectrificationRoute = (
  <Switch>
    {/*<Redirect from="/?Code*" to="/" />*/}
    {/* <Route exact path="/" render={() => <NavigationPage />} /> */}
    <Redirect from="/signin-oidc*" to="/" />
    <Redirect from="/signin-oidc#code=*" to="/" />
    <Route exact path="/" render={() => <Landing />} />
    {/*<Route exact path="/admin" render={() => <AdminLandingPage />} />*/}
    {/*<Route exact path="/userManagement" render={() => <UserManagement />} />*/}
  {/*  <Route exact path="/fileUpload" render={() => <FileUpload />} />*/}
    <Route
      exact
      path="/fileUploadExternal"
      render={() => <FileUploadExternal />}
    />
    <Route exact path="/viewDetail" render={() => <ViewDetails />} />
    <Route exact path="/digitalId" render={() => <DigitalID />} />
        {/*  <Route exact path="/approveReject" render={() => <ApproveReject />} />*/}
        <Route exact path="/GetDetails" render={() => <DetailsPage />} />
       
        <Route render={() => <Error />} />

  </Switch>
);

export const QRCalibRoute = (
    <Switch>
        {/*<Redirect from="/?Code*" to="/" />*/}
        {/* <Route exact path="/" render={() => <NavigationPage />} /> */}
        <Redirect from="/signin-oidc*" to="/" />
        <Redirect from="/signin-oidc#code=*" to="/" />
        <Route exact path="/" render={() => <QRHome />} />
        <Route exact path="/qrhome" render={() => <QRHome />} />
        {/*<Route exact path="/userManagement" render={() => <UserManagement />} />*/}
       {/* <Route exact path="/caliberationList" render={() => <CaliberationList />} />*/}
        <Route exact path="/caliberationUpload" render={() => <QrInputs />} />
       
        <Route exact path="/QRScanCalibrationCertificateFTP" render={() => <GetCalibCertDetails />} />
        <Route render={() => <Error />} />
    </Switch>
);

export const LandingRoute = (
    <Switch>
        {/*<Redirect from="/?Code*" to="/" />*/}
        <Redirect from="/signin-oidc*" to="/" />
        <Redirect from="/signin-oidc#code=*" to="/" />
        <Route exact path="/" render={() => <NavigationPage />} />
        <Route render={() => <Error />} />
    </Switch>
);
